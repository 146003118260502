@font-face {
    font-family: 'Seurat';
    src: url('./assets/fonts/Seurat.otf');
}

.MuiTypography-root {
    @media (prefers-color-scheme: dark) {
      :root {
        color: #ffffff; /* Set your dark mode color here */
      }
    }

    @media (prefers-color-scheme: light) {
      :root {
        color: #393322; /* Set your light mode color here */
      }
    }
  }

* { 
    font-family: 'Seurat', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-appear-active,
.fade-enter-active {
  opacity: 1;
}